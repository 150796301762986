window.kanbanSidebarAdded = false;

frappe.router.on('change', function () {
    if (window.kanbanSidebarAdded) {
        return;
    }

    let route = frappe.get_route();
    if (route.length < 3 || route[0] !== "List" || route[1] !== "Task" || route[2] !== "Kanban") {
        return;
    }

    frappe.call({
        method: "neoatlas_projects.api.kanban.render_sidebar",
        callback: function (response) {
            const buttons = $(".page-container:not(#page-Workspaces) .layout-side-section");
            buttons.html(response.message);
            buttons.css("border-right", "1px solid var(--border-color)")

            const filterSection = $('.kanban-sidebar .filter-section');

            filterSection.find('button[data-action="self-assigned"]').on("click", function () {
                cur_list.filter_area.add([["Task", "_assign", "like", "%" + frappe.session.user + "%"]]);
            });

            filterSection.find('button[data-action="current-time-period"]').on("click", function () {
                const today = moment().format("YYYY-MM-DD");

                cur_list.filter_area.add([
                    ["Task", "exp_start_date", "<=", today],
                    ["Task", "exp_end_date", ">=", today],
                ]);
            });

            window.kanbanSidebarAdded = true;
        }
    });
});