export class Form {
    // Returns a list of years used as dropdown options
    static get_options_years() {
        let option_years = [];

        for (let i = 2024; i < 2030; i++) {
            option_years.push(i.toString());
        }

        return option_years;
    }

    // Returns a list of calendar weeks used as dropdown options
    static get_options_calendar_weeks() {
        let option_weeks = [];
        for (let i = 1; i < 53; i++) {
            option_weeks.push("Week " + i.toString().padStart(2, "0"));
        }

        return option_weeks;
    }

    // Assigns time frame information to a calendar week dropdown
    static assign_calendar_week_timeframe(year, selector) {
        let week_index = 1;

        $(selector + " option").each(function () {
            let element = $(this);
            let label = element.html();

            if (label === "") {
                return;
            }

            if (label.includes(" (")) {
                label = label.split(' (')[0];
            }

            const startDate = moment().isoWeekYear(year).isoWeek(week_index).clone().startOf('isoWeek');

            element.html(label + " (" + startDate.format("DD.MM.") + ")")
            week_index++;
        })
    }
}