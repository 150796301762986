const TYPE_ITEM_LINKED = ["R&D: Electronics", "R&D: Mechanics", "Procurement", "Manufacturing", "Quality assurance"]

frappe.ui.form.on('Task', {
    refresh(form) {
        update_calendar_week(form);

        if (!form.is_new()) {
            form.add_custom_button(__('Create follow-up ticket'), function () {
                let fields = {};

                if (form.doc.parent_task !== undefined && form.doc.parent_task !== null && form.doc.parent_task !== "") {
                    fields.parent_task = form.doc.parent_task;
                }

                if (form.doc.project !== undefined && form.doc.project !== null && form.doc.project !== "") {
                    fields.project = form.doc.project;
                }

                frappe.route_options = fields;
                frappe.set_route('Form', 'Task', 'new-target-doctype-1');
            }, null);
        }
    },

    parent_task(form) {
        frappe.db.get_doc('Task', form.doc.parent_task)
            .then(doc => {
                if (form.doc.type === undefined || form.doc.type === "") {
                    form.doc.type = doc.type;
                    form.refresh_field('type');
                }

                if (form.doc.color === undefined) {
                    form.doc.color = doc.color;
                    form.refresh_field('color');
                }

                if (doc.exp_start_date !== null) {
                    let start_date = moment(doc.exp_start_date);
                    let today = moment()

                    if (start_date < today) {
                        start_date = today.startOf('isoWeek');
                    }

                    form.doc.exp_start_date = start_date.format("YYYY-MM-DD");
                    form.refresh_field('exp_start_date');

                    form.doc.exp_end_date = start_date.endOf('isoWeek').format("YYYY-MM-DD");
                    form.refresh_field('exp_end_date');

                    update_calendar_week(form);
                }

                if (TYPE_ITEM_LINKED.includes(form.doc.type) && (form.doc.custom_item === undefined || form.doc.custom_item === "")) {
                    form.doc.custom_item = doc.custom_item;
                    form.refresh_field('custom_item');

                    form.doc.custom_item_revision = doc.custom_item_revision;
                    form.refresh_field('custom_item_revision');
                    return;
                }

                if (form.doc.type === "R&D: Software" && (form.doc.custom_software_release === undefined || form.doc.custom_software_release === "")) {
                    form.doc.custom_software_release = doc.custom_software_release;
                    form.refresh_field('custom_software_release');
                }
            });
    },

    custom_calendar_week(form) {
        let start_date = form.doc.exp_start_date;
        let year = moment().year();

        if (start_date !== undefined && start_date !== null && start_date !== "") {
            year = moment(start_date).year();
        }

        let week = form.doc.custom_calendar_week.substring(5);
        let start = moment(year.toString() + "W" + week);
        let end = start.clone().endOf("isoWeek");

        form.doc.exp_start_date = start.format("YYYY-MM-DD");
        form.refresh_field('exp_start_date');

        form.doc.exp_end_date = end.format("YYYY-MM-DD");
        form.refresh_field('exp_end_date');
    },

    exp_start_date(form) {
        update_calendar_week(form);
    },

    exp_end_date(form) {
        update_calendar_week(form);
    },
})

function update_calendar_week(form) {
    let start_date = form.doc.exp_start_date;
    let year = moment().year();

    if (start_date === undefined || start_date === null || start_date === "") {
        form.doc.custom_calendar_week = "";
    } else {
        start_date = moment(start_date);
        let week = start_date.isoWeek().toString();
        form.doc.custom_calendar_week = "Week " + week.padStart(2, "0");

        year = start_date.year();
    }

    form.refresh_field('custom_calendar_week');
    NeoAtlasProjects.form.assign_calendar_week_timeframe(year, '.form-page select[data-fieldname="custom_calendar_week"]')
}